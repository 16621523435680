import React, { useContext } from "react";
import styled from "styled-components";

import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CopyAllIcon from "@mui/icons-material/CopyAll";

import Typography from "@mui/material/Typography";

import IncidentDataCard from "./IncidentDataCard";

import { IncidentContext } from "../../store/incident-context";

import incidentSource from "lib/incidentSource";

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const ToggleDrawer = React.forwardRef((props, ref) => {
  const [incidentState, dispatchIncident] = useContext(IncidentContext);
  const { activeIncidentId } = incidentState;
  const incident = incidentState.calls.find(
    (call) => call.phone === activeIncidentId
  );
  if (!incident) {
    return (
      <div
        ref={ref}
      />
    );
  }

  const incidentMetadata = incident.metadata;
  
  // We want to unselect an incident by setting to null
  const closeDrawer = () => {
    dispatchIncident({ type: "ACTIVE_INCIDENT", payload: null });
  };

  return (
    <Paper
      ref={ref}
      {...props}
      sx={{
        width: 400,
        borderColor: "primary.main",
        marginLeft: "260px",
        height: "100%",
        overflowY: 'scroll'
      }}
      square
      elevation={24}
    >
      <Toolbar />
      <Box
      sx={{
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
      >
        <Typography 
          sx={{
            fontWeight: 'bold',
            color: 'green.main'
          }}
        >
          {incident ? incident.phone : null}
          <br></br>
          <Typography>Source: {incidentSource[incidentMetadata.source].title}</Typography>
        </Typography>
        <FlexDiv>
          <FlexDiv style={{ display: "flex", alignItems: "center" }}>
            <IconButton>
              <CopyAllIcon />
            </IconButton>
            <Typography>Copy All</Typography>
          </FlexDiv>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </FlexDiv>
      </Box>
      <IncidentDataCard />
    </Paper>
  );
});

export default ToggleDrawer;
