import PhoneIcon from '@mui/icons-material/Phone';

const cellIcon = <img src={`${process.env.PUBLIC_URL}/img/svgs/callType/cell_icon.svg`} style={{ width: '20px' }} />;
const landlineIcon = <img src={`${process.env.PUBLIC_URL}/img/svgs/callType/landline_icon.svg`} style={{ width: '20px' }} />;

const incidentSource = {
  "APPLE": {
    title: "Apple",
    icon: () => cellIcon
  },
  "GOOGLE": {
    title: "Google",
    icon: () => cellIcon
  },
  "ANIALI": {
    title: "ANI/ALI",
    icon: () => landlineIcon
  }
};

export default incidentSource;