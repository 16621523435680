const light = {
  hover: {
    main: 'rgb(247, 247, 247)'
  },
  selected: {
    main: '#DAF2FD'
  },
  green: {
    main: '#00825D'
  },
  gray: {
    main: '#636363'
  }
};

const dark = {
  hover: {
    main: 'rgb(25, 54, 81)'
  },
  selected: {
    main: '#004367'
  },
  green: {
    main: '#30E0AC'
  },
  gray: {
    main: '#96ADC2'
  }
};

const getDesignTokens = (mode) => ({
  typography: {
    body1: {
      fontWeight: 300
    }
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? light
      : dark),
  },
});

export default getDesignTokens;