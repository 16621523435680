import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import ColorModeContext from './store/colorMode-context';
import { IncidentController } from './store/incident-context';

import getDesignTokens from './lib/themes';

import Content from './components/Content';


import esriConfig from "@arcgis/core/config.js";

esriConfig.assetsPath = "./assets";
esriConfig.apiKey =
  "AAPK3248c14cb636436bb50a7bac1fadd2718lgzx8Ma4SAOLMDdU4KtKZLTY0X2ZuM441wJkLo_LCtEX5SIOF3hOJ9uGMCw7zug";

export default function App() {
  // Color shit dont touch
  const [mode, setMode] = React.useState('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);  
  // color shit end
  // =================

  return (
    <ColorModeContext.Provider value={colorMode}>
      <IncidentController>
        <ThemeProvider theme={theme}>
          <Content />
        </ThemeProvider>
      </IncidentController>
    </ColorModeContext.Provider>
  );
};