import React, { useContext, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { IncidentContext } from '../../store/incident-context';

import incidentSource from 'lib/incidentSource';
import ColorModeContext from '../../store/colorMode-context';

const Incident = ({ incident }) => {
  const [date, setDate] = useState(new Date());
  const [incidentState, dispatchIncident] = useContext(IncidentContext);
  const colorMode = useContext(ColorModeContext);

  const incidentMetadata = incident.metadata;
  const IconToUse = incidentSource[incidentMetadata.source].icon;

  const selectIncident = (incidentPhone) => {
    dispatchIncident({ type: "ACTIVE_INCIDENT", payload: incidentPhone });
  };

  incidentState.activeIncidentId

  return (
    <Box 
      sx={{ 
      padding: '10px', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between',  
      borderBottom: 1, 
      borderColor: 'divider',
      backgroundColor: incidentState.activeIncidentId === incident.phone ? 'selected.main' : 'transparent',
      '&:hover': {
        backgroundColor: 'hover.main',
        opacity: [0.9, 0.8, 0.7],
      }}}
      onClick={() => selectIncident(incident.phone) }
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
          <IconToUse />
       
        <Typography sx={{ marginLeft: 2, fontWeight: 300 }}>{incident.phone}</Typography>
      </Box>
      <Typography sx={{ fontSize: '11px'}}>{date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}</Typography>
    </Box>
  )
};

export default Incident;