import React, { createContext, useReducer, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import mockData from '../mock-data/mainMockData';

// let initialState = {
//   schema: {
//     account: [{
//         name: "FairfaxDPSC Account",
//         shapefile: {},
//       }],
//       incidents: {
//         alerts: [],
//         calls: [],
//       },
//       layers: {
//         staticLayers: [],
//         dynamicLayers: [],
//         mapLayers: [],
//       },
//   },
//   alerts: [],
//   calls: [],
//   activeIncidentId: null,
//   dynamicLayers: [],
//   staticLayers: [],
//   markers: {},
// };

let initialState = {
  schema: mockData,
  alerts: [],
  calls: mockData.incidents.calls,
  activeIncidentId: null,
  dynamicLayers: [],
  staticLayers: [],
  markers: {},
};

const initialContext = [{ ...initialState }, () => {}];

export const IncidentContext = createContext(initialContext);

let index;

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT_SCHEMA":
        return { ...state, schema: action.payload, calls: action.payload.incidents.calls }
    case "ADD_LOCATION":
      index = state.locations.findIndex((item) => item.ID === action.payload.ID);

      if (index < 0) {
        return {
          ...state,
          locations: [...state.locations, { ...action.payload }],
        };
      }

      const locationUpdate = state.locations[index];
      locationUpdate.locations.push(action.payload.locations[0]);

      // locationUpdate.deviceSource = action.payload.deviceSource;
      locationUpdate.callType = action.payload.callType;
      locationUpdate.adr = action.payload.adr;
      locationUpdate.aniAli = action.payload.aniAli;

      const newData = [...state.locations.slice(0, index), locationUpdate, ...state.locations.slice(index + 1)];
      return { ...state, locations: newData };

    case "ADD_ALERT":
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };

    case "UPDATE_CALL_STATE":
      index = state.locations.findIndex((item) => item.ID === action.payload.id);

      if (index < 0) {
        return {
          ...state,
        };
      }

      const stateUpdate = state.locations[index];
      stateUpdate.currentState = action.payload.newDeviceState;

      const newStateData = [...state.locations.slice(0, index), stateUpdate, ...state.locations.slice(index + 1)];

      return { ...state, locations: newStateData };

    case "CREATE_MARKER":
      let marker = {};
      marker[action.payload.id] = action.payload.marker;
      return { ...state, markers: { ...state.markers, ...marker } };

    case "RESET_MARKERS":
      return { ...state, markers: {} };

    case "DELETE_LOCATIONS":
      return { ...state, locations: [], activeIncidentId: null, markers: {} };

    case "ACTIVE_INCIDENT":
      if (!action.payload) return { ...state, activeIncidentId: null };

      return { ...state, activeIncidentId: action.payload };

    default:
      console.log("Unrecognized Action", action);

      throw new Error(`Unrecognized action: ${action}`);
  }
};

export function IncidentController(props) {
  const [incidentState, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [incidentState, dispatch], [incidentState]);

  return <IncidentContext.Provider value={value}>{props.children}</IncidentContext.Provider>;
}

IncidentController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};















// ==============================================================

// const IncidentContext = React.createContext({ incident: null, selectIncident: () => {} });

// export default IncidentContext;