import React from 'react';
import styled from 'styled-components';

import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

// Something to leverage if this grows too large
const IGNORED_LABELS = ["site", "user"];

const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${props => props.hasObjVal ? 'column' : 'row'};
`;

const DataPoint = ({ data, index }) => {
  const hasObjVal = typeof data.value === 'object';

  return (
    <FlexDiv hasObjVal={hasObjVal}>
      <Typography
         sx={{
          color: !hasObjVal && 'gray.main',
          width: hasObjVal ? '100%' : '50%',
          marginLeft: '15px',
          marginBottom: '15px',
          marginTop: index === 0 ? '15px' : 0,
          fontWeight: hasObjVal ? 'bold' : 'none'
        }}
      >
        {data.label}{!hasObjVal && ':'}
      </Typography>
      {hasObjVal && <Divider />}
      <Typography
         sx={{
          width: '100%',
          marginRight: '15px',
          marginBottom: '15px',
          marginTop: index === 0 ? '15px' : 0
        }}
      >
        {data.value}
      </Typography>
    </FlexDiv>
  );
};

const InnerData = ({ val, isArray }) => {
  const tempArr = [];

  if (isArray) {
    val.forEach(data => {
      for (const property in data) {
        tempArr.push({ label: property, value: data[property]});
      }
    });
  } else {
    for (const property in val) {
      tempArr.push({ label: property, value: val[property]})
    }
  }

  return (
    <div>
      {tempArr.map((value, index) => {
        return <DataPoint data={value} index={index}/>
      })}
    </div>
  )

};

const DataCard = ({ data }) => {
  const dataCards = data.variables;
  const dataArr = [];

  for (const label in dataCards) {
    let assignedVal = null;
    const val = dataCards[label];

    if (label !== "site" && label !== "user") {
      assignedVal = <InnerData val={val} isArray={Array.isArray(val)} />;
      dataArr.push({ label, value: assignedVal })
    }
  }

  return (
    <Box fontWeight={300}>
      {/* Data Card Title */}
      <Typography 
        sx={{
          margin: '15px',
          fontWeight: 'bold'
        }}
      >
        {data.name}
      </Typography>
      {dataArr.map(data => {
        return <DataPoint data={data} />
      })}
    </Box>
  )
};

export default DataCard;