import React, { useContext } from 'react';

import { IncidentContext } from "../../store/incident-context";

import Drawer from '@mui/material/Drawer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';

import TabPanel from './TabPanel';
import ToggleDrawer from './ToggleDrawer';
import Typography from '@mui/material/Typography';

import IncidentPanel from './IncidentPanel';


const drawerWidth = 260;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Sidebar = () => {
  const [incidentState, dispatchIncident] = useContext(IncidentContext);

  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <div style={{ height: '100vh' }}>
      <Box>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto'}}>
            <Box>
              <Tabs value={tab} 
                onChange={handleTabChange} 
                centered variant="fullWidth"
              >
                <Tab label="911" {...a11yProps(0)} />
                <Tab label="Alerts" {...a11yProps(1)} />
              </Tabs>
            </Box>
            {/* <TabPanel value={tab} index={0}>
              <Typography sx={{ fontWeight: 'bold'}}>
                911
              </Typography>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Typography sx={{ fontWeight: 'bold'}}>
                Alerts
              </Typography>
            </TabPanel> */}
          </Box>
          <Divider />
          <IncidentPanel selectedTab={tab} />
        
        </Drawer>
      </Box>
      <Slide direction="right" in={incidentState.activeIncidentId} mountOnEnter unmountOnExit>
        <ToggleDrawer />
      </Slide>
    </div>
  )
};

export default Sidebar;