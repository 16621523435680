import React, { useRef, useEffect, useContext, useState } from "react";
import styled from "styled-components";
import Toolbar from '@mui/material/Toolbar';

import "@arcgis/core/assets/esri/themes/light/main.css";

// import { Map } from "@esri/react-arcgis";
import { IncidentContext } from "../../store/incident-context";

import ArcGISMap from "@arcgis/core/Map"; // generates the map, on 269
import LayerList from "@arcgis/core/widgets/LayerList";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
// import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import Point from "@arcgis/core/geometry/Point";
import Circle from "@arcgis/core/geometry/Circle";

const FullMap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: flex-start;
  overflow: unset;
  cursor: inherit;
  position: static;
  width: 100%;
`;

// Eventually we want to move this marker stuff out into its own file

// const LOCATION_MARKER_ATTR = {

// }

// import * as Icons from 'constants/icons'

// Icons[schema.metadata.marker]


const locationMarkerAttr = {
  symbol: {
    type: "picture-marker", // autocasts as new SimpleMarkerSymbol()
    // use an SVG path to create an arrow shape
    url: `${process.env.PUBLIC_URL}/img/svgs/location.svg`,
    width: "28px",
    height: "37px",
    yoffset: 12,
  },
};

const cellCallMarkerAttr = {
  symbol: {
    type: "picture-marker", // autocasts as new SimpleMarkerSymbol()
    // use an SVG path to create an arrow shape
    url: `${process.env.PUBLIC_URL}/img/svgs/markers/cell_call_event.svg`,
    width: "42px",
    height: "42px"
  },
};

const landlineCallMarkerAttr = {
  symbol: {
    type: "picture-marker", // autocasts as new SimpleMarkerSymbol()
    // use an SVG path to create an arrow shape
    url: `${process.env.PUBLIC_URL}/img/svgs/markers/landline_call_event.svg`,
    width: "42px",
    height: "42px"
  },
};

const aniAliMarkerAttr = {
  symbol: {
    type: "picture-marker", // autocasts as new SimpleMarkerSymbol()
    // use an SVG path to create an arrow shape
    url: `${process.env.PUBLIC_URL}/img/svgs/markers/ani_ali_event.svg`,
    width: "42px",
    height: "42px",
  },
};

const primaryBoundsAttr = {
  renderer: {
    type: "simple",
    symbol: {
      type: "simple-fill",
      size: 6,
      color: "transparent",
      outline: {
        width: 1.5,
        color: "#3a1d4d",
      },
    },
  },
};

const expandedBoundsAttr = {
  renderer: {
    type: "simple",
    symbol: {
      type: "simple-fill",
      size: 6,
      color: "transparent",
      outline: {
        width: 1.0,
        color: "#70b0e4",
      },
    },
  },
};

const Map = () => {
  const [incidentState, dispatchIncident] = useContext(IncidentContext);
  const [jvLayer, setJvLayer] = useState(null);
  const [mapView, setMapView] = useState(null);
  const [callMarkers, setCallMarkers] = useState(null);
  const mapRef = useRef(null);

  const selectIncident = (incidentPhone) => {
    dispatchIncident({ type: "ACTIVE_INCIDENT", payload: incidentPhone });
  };

  const { calls, markers, activeIncidentId, alerts } = incidentState;

  const moveGraphic = (graphic, geometry, currentState) => {
    try {
      let newGraphic = graphic.clone();
      let currentStateSymbol;

      switch (currentState) {
        case "PENDING":
          currentStateSymbol = pendingLocationMarkerAttr.symbol;
          break;
        case "PAUSED":
          currentStateSymbol = pausedLocationMarkerAttr.symbol;
          break;
        case "RELEASED":
          currentStateSymbol = releasedLocationMarkerAttr.symbol;
          break;

        default:
          currentStateSymbol = locationMarkerAttr.symbol;
      }

      newGraphic.geometry = geometry;
      newGraphic.symbol = currentStateSymbol;
      return newGraphic;
    } catch (err) {
      console.log(err);
    }
  };

  const stateName = (state) => {
    switch (state) {
      case "PENDING":
        return "RING";
      case "ACTIVE":
        return "TALK";
      case "PAUSED":
        return "ON HOLD";
      case "RELEASED":
        return "RELEASED";

      default:
        return "";
    }
  };

  const getTemplate = (incident, latitude, longitude) => {
    return {
      title: incident.phone,
      content: `<p>Lat/Long:${latitude}, ${longitude}</p>`,
    };
  };

  const drawMarker = (incident, latitude, longitude, breadCrumbs = false) => {
    // It's a cell call that also has an aniAli property
    if (incident.metadata.source === "APPLE" || incident.metadata.source === "GOOGLE") {
      return new Graphic({
        ...cellCallMarkerAttr,
        geometry: {
          type: "point",
          longitude,
          latitude,
        },
        attributes: {
          "phone": incident.phone
        }
      });
    } else {
      return new Graphic({
        ...landlineCallMarkerAttr,
        // popupTemplate: getTemplate(incident, latitude, longitude),
        geometry: {
          type: "point",
          longitude,
          latitude,
        },
        attributes: {
          "phone": incident.phone
        }
      });
    }

    
 

    // switch (incident.currentState) {
    //   case "PENDING":
    //     return new Graphic({
    //       ...(!breadCrumbs ? pendingLocationMarkerAttr : locationHistoryMarkerAttr),
    //       popupTemplate: getTemplate(incident, latitude, longitude),
    //       geometry: {
    //         type: "point",
    //         longitude,
    //         latitude,
    //       },
    //     });
    //   case "PAUSED":
    //     return new Graphic({
    //       ...(!breadCrumbs ? pausedLocationMarkerAttr : locationHistoryMarkerAttr),
    //       popupTemplate: getTemplate(incident, latitude, longitude),
    //       geometry: {
    //         type: "point",
    //         longitude,
    //         latitude,
    //       },
    //     });
    //   case "RELEASED":
    //     return new Graphic({
    //       ...(!breadCrumbs ? releasedLocationMarkerAttr : locationHistoryMarkerAttr),
    //       popupTemplate: getTemplate(incident, latitude, longitude),
    //       geometry: {
    //         type: "point",
    //         longitude,
    //         latitude,
    //       },
    //     });

    //   default: {
    //     return new Graphic({
    //       ...(!breadCrumbs ? locationMarkerAttr : locationHistoryMarkerAttr),
    //       popupTemplate: getTemplate(incident, latitude, longitude),
    //       geometry: {
    //         type: "point",
    //         longitude,
    //         latitude,
    //       },
    //     });
    //   }
    // }
  };

  useEffect(() => {
    if (mapRef.current) {
      // create primary polygon
      // draws geojson shapefile ontop of esri
      const { schema } = incidentState;
      const { featureLayers, mapImageLayers } = schema.layers;
      
      const polygonBlob = new Blob([JSON.stringify(schema.account.jurisdiction[0].shapefile)], {
        type: "application/json",
      });
      const polygonUrl = URL.createObjectURL(polygonBlob);
      const polygonLayer = new GeoJSONLayer({
        listMode: "hide",
        url: polygonUrl,
        ...primaryBoundsAttr,
        title: "Jurisdictional Boundary",
        elevationInfo: {
          mode: "relative-to-scene",
          offset: 99,
          unit: "meters",
        },
      });

      setJvLayer(polygonLayer);
      
      const map = new ArcGISMap({
        basemap: "streets-navigation-vector",
        layers: [polygonLayer],
      });

      const view = new MapView({
        map,
        container: mapRef.current,
      });


      // Add all of the layers
      featureLayers.forEach(layer => {
        const featureLayer = new FeatureLayer({ url: layer });
        featureLayer.visible = false;
        map.add(featureLayer);
      });

      mapImageLayers.forEach(layer => {
        const mapImageLayer = new MapImageLayer({ url: layer });
        mapImageLayer.visible = false;
        map.add(mapImageLayer);
        // const fs2 = new MapImageLayer({
        //   url: "https://nowcoast.noaa.gov/arcgis/rest/services/nowcoast/radar_meteo_imagery_nexrad_time/MapServer?f=json",
        //   opacity: 0.5,
        //   visible: false,
        //   elevationInfo: {
        //     mode: "relative-to-ground",
        //     offset: 1,
        //     unit: "meters",
        //   },
        //   // sublayers: [{ id: 7 }],
        // });
      });

      setMapView(view);

      const layerList = new LayerList({
        view: view,
      });

      view.ui.add(layerList, "top-right");

      if (!incidentState.activeIncidentId) {
        // center polygons on screen
        polygonLayer
          .when(() => {
            return polygonLayer.queryExtent();
          })
          .then((response) => {
            view.goTo(response.extent);
          });
      }

      return () => {
        view && view.destroy();
      };
    }
  }, []);

  // update all incidents
  useEffect(() => {
    if (!mapView) return;
    if (activeIncidentId !== null) return;

    mapView.graphics.removeAll();

    let locationList = [];

    // alerts.forEach((row, index) => {
    //   const { latitude, longitude, accuracy } = row.event.location.geodetic;
    //   const addedGraphic = drawMarker(row, latitude, longitude);
    //   locationList.push(addedGraphic);
    // });

    calls.forEach((row, index) => {
      const lastIndex = row.locations.length - 1;
      const { phone, currentState } = row;
      const { latitude, longitude, accuracy } = row.locations[lastIndex];
      const graphic = markers[phone];

      if (!graphic || typeof graphic.clone === "undefined") {
        const addedGraphic = drawMarker(row, latitude, longitude);

        dispatchIncident({ type: "CREATE_MARKER", payload: { id: phone, marker: addedGraphic } });
        locationList.push(addedGraphic);
      } else {
        const geometry = {
          type: "point",
          longitude,
          latitude,
        };

        locationList.push(moveGraphic(graphic, geometry, currentState));
      }
    });

    mapView.graphics.addMany(locationList);
    setCallMarkers(locationList);

    jvLayer
      .when(() => {
        return jvLayer.queryExtent();
      })
      .then((response) => {
        mapView.goTo(response.extent);
      });
  }, [activeIncidentId, calls, alerts, mapView]);

  // update active incident
  useEffect(() => {
    if (!mapView) return;
    if (activeIncidentId === null) return;

    mapView.graphics.removeAll();

    const currentCall = calls.find((item) => item.phone === activeIncidentId);

    const currentCalls = currentCall.locations;
    const { latitude, longitude } = currentCalls[currentCalls.length - 1];

    let locationList = [];
    let radiusList = [];
    let otherList = [];
    dispatchIncident({ type: "RESET_MARKERS", data: null });
    if (currentCall.aniAli) {

      const aniAli = currentCall.aniAli;
      
      const lineSymbol = {
        symbol: {
          type: "simple-line",
          color: "#ffffff",
          width: 4,
          style: "short-dash",
        },
      };

      const lineMarker = new Graphic({
        ...lineSymbol,
        geometry: {
          type: "polyline",
          paths: [
            [aniAli.longitude, aniAli.latitude],
            [longitude, latitude],
          ],
        },
      });

      const circleGeometry = new Circle({
        center: {
          type: "point",
          longitude: longitude,
          latitude: latitude,
        },
        geodesic: true,
        // numberOfPoints: accuracy,
        radius: 30,
        radiusUnit: "meters",
      });

      const radiusMarker = new Graphic({
        geometry: circleGeometry,
        symbol: {
          style: "solid",
          type: "simple-fill",
          outline: {
            width: 1,
            color: "#007B58",
          },
          color: [0, 191, 136, 0.3],
        },
      });

      radiusList.push(radiusMarker);
      otherList.push(lineMarker);

      const aniAliMarker = new Graphic({
        ...aniAliMarkerAttr,
        // popupTemplate: getTemplate(incident, latitude, longitude),
        geometry: {
          type: "point",
          longitude: currentCall.aniAli.longitude,
          latitude: currentCall.aniAli.latitude,
        },
      });

      locationList.push(aniAliMarker);
    }

    currentCall.locations.forEach((row, index, arr) => {
      const { latitude, longitude, accuracy } = row;
      const addedGraphic = drawMarker(currentCall, latitude, longitude, arr.length > 1 && index !== arr.length - 1);

      if (index === arr.length - 1) {
        const circleGeometry = new Circle({
          center: {
            type: "point",
            longitude: longitude,
            latitude: latitude,
          },
          geodesic: true,
          // numberOfPoints: accuracy,
          radius: accuracy,
          radiusUnit: "meters",
        });

        const radiusMarker = new Graphic({
          geometry: circleGeometry,
          symbol: {
            style: "solid",
            type: "simple-fill",
            outline: {
              width: 1,
              color: "#4cc99d",
            },
            color: [76, 201, 157, 0.4],
          },
        });

        radiusList.push(radiusMarker);
      }

      locationList.push(addedGraphic);
    });

    mapView.graphics.addMany(radiusList);
    mapView.graphics.addMany(otherList);
    mapView.graphics.addMany(locationList);

    var pt = new Point({
      longitude,
      latitude,
    });

    // this goes to the point
    mapView.goTo({
      target: pt,
      zoom: 16
    });

    // mapView.goTo({
    //   center: [longitude, latitude]
    // }).then(function () {
    //   // if (mapView.zoom < 12) mapView.zoom = 16;
    // });
  }, [activeIncidentId, calls, mapView]);

  useEffect(() => {
    if (!mapView) return;
    const clickHandler = mapView.on('click', evt => {
      mapView.hitTest(evt).then(response => {
        if (response.results.length) {
          const clickedMarker = callMarkers.find(marker => {
            // assume no overlapping markers
            return (
              marker?.uid ===
              response?.results[0]?.graphic?.uid
            );
          });
          if (clickedMarker.attributes.phone) {
            selectIncident(clickedMarker.attributes.phone)
          }
        }
      });
    });
  }, [callMarkers, mapView]);

  return (
    <FullMap>
      <div ref={mapRef} style={{ width: "100%", height: "calc(100% - 52px)", marginTop: '65px' }}></div>
    </FullMap>
  );
};

export default Map;