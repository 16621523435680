import React from 'react';
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';

const AniAliData = ({ data }) => {
  return (
    <>
      <Card variant="outlined" sx={{ padding: '10px'}}>
        <Typography sx={{ color: 'gray.main', fontSize: 12 }}>Source: ANI/ALI (Phase2)</Typography>
        <Typography>{data.address.street}, {data.address.cityZip}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }} >
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray.main', marginRight: '5px'}}>
            <ModeStandbyIcon sx={{ fontSize: 12 }} />Uncertainty Radius: 
          </Box>
          <Typography sx={{ fontSize: 12 }}>5.0m</Typography>
        </Box>
        
      </Card>
      <Typography sx={{ fontSize: 12, marginTop: '10px'}}>Most Recent Locations, Estimated Addresses</Typography>
    </>
  );
};

export default AniAliData;