import React, { useEffect, useContext } from 'react';
import Box from '@mui/material/Box';

import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import Map from './Map/Map';
import { IncidentContext } from '../store/incident-context';

// import mockData from '../mock-data/mainMockData.json';


const Content = () => {

  // const [incidentState, dispatchIncident] = useContext(IncidentContext);

  // useEffect(() => {
  //   console.log("Init Schema")
  //   dispatchIncident({ type: "INIT_SCHEMA", payload: mockData });
  // }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Sidebar />
      <Map />
    </Box>
  );
};

export default Content;