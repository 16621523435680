import React, { useContext } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Incident from "./Incident";

import { IncidentContext } from "store/incident-context";

const NoIncidentsCard = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "90px" }}>
      <Card sx={{ maxWidth: "75%" }}>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        >
          <Typography sx={{ fontWeight: "bold" }} gutterBottom>
            No Emergencies
          </Typography>
          <Typography variant="body2">
            No emergency events were found.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const tempMap = {
  0: "calls",
  1: "alerts",
};

const IncidentPanel = (props) => {
  // Temporary - default all ALERTS to none
  if (props.selectedTab === 1) {
    return <NoIncidentsCard />
  }

  const [incidentState, dispatchIncident] = useContext(IncidentContext);

  const incidentsToShow =
    incidentState.schema.incidents[tempMap[props.selectedTab]];

  const noIncidents = !incidentsToShow.length;

  return (
    <>
      {noIncidents ? (
        <NoIncidentsCard />
      ) : (
        incidentsToShow.map((incident) => <Incident incident={incident} />)
      )}
    </>
  );
};

export default IncidentPanel;
