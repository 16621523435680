import React, { useContext } from 'react';
import styled from 'styled-components';

import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import LanguageIcon from '@mui/icons-material/Language';
import Divider from '@mui/material/Divider';
import { IncidentContext } from "../../store/incident-context";

import AniAliData from './AniAliData';
import DataCard from './DataCard';

const PaddedDiv = styled.div`
  padding: 20px;
`;

const IncidentDataCard = () => {
  const [incidentState, dispatchIncident] = useContext(IncidentContext);
  const { activeIncidentId } = incidentState;
  const incident = incidentState.calls.find(
    (call) => call.phone === activeIncidentId
  );
  const incidentData = incident.metadata;
  const incidentDataCards = incident.dataCards;
  return (
    <>
      <Card
        sx={{
          margin: "20px 20px 0 20px"
        }}
        variant="outlined"
      >
        <PaddedDiv>
          <Typography style={{ fontWeight: "bold" }}>
            {incidentData.address.street}
          </Typography>
          <Typography>{incidentData.address.cityZip}</Typography>
          <div style={{ display: "flex", alignItems: "center", marginBottom: '10px' }}>
            <LanguageIcon sx={{color: 'gray.main', fontSize: 12}} />
            <Typography sx={{color: 'gray.main', marginRight: '5px'}}>
              Lat/Long:
            </Typography>
            <Typography>
              {`${incident.locations[0].latitude.substring(0, 11)}, ${incident.locations[0].longitude.substring(0, 11)}`}
            </Typography>
          </div>
          {incident.aniAli ? <AniAliData data={incident.aniAli} /> : null}
        </PaddedDiv>
        <Divider variant={"fullWidth"} />
        <PaddedDiv>
          <Card
            variant="outlined"
          >
            {incidentDataCards.map(dataCard => {
              return <DataCard data={dataCard} />
            })}
          </Card>
        </PaddedDiv>
      </Card>
    </>
  );
};

export default IncidentDataCard;