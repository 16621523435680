import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import ToggleColorBtn from './ToggleColorBtn';

import RsosLogo from 'assets/RsosLogo.png';

const Header = () => {
  return (
    <AppBar color="default" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        {/* Left Side */}
        <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '300px'}}>
          <Typography sx={{ fontSize: 21, fontWeight: 200 }}>RapidSOS</Typography>
          <img src={RsosLogo} alt="RapidSOS Logo" style={{ width: '35px' }} />
          <Typography sx={{ fontSize: 21, fontWeight: 200 }}>Portal Prototype</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {/* Right Side */}
        <IconButton><VolumeOffIcon /></IconButton>
        <ToggleColorBtn />
        <IconButton color="inherit"><MenuIcon /></IconButton>
        </Toolbar>
    </AppBar>
  );
};

export default Header;